var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',[_c('v-main',[_c('v-row',{staticClass:"justify-center mt-5"},[_c('v-card',{staticClass:"mt-3",attrs:{"width":"1175px","flat":"","color":"#f1f1f1"}},[_c('v-card-text',[_c('h2',{staticClass:"evisa-header mb-5"},[_vm._v(_vm._s(_vm.$t("stepThree.subtitle2")))]),_c('v-alert',{attrs:{"border":"bottom","colored-border":"","type":_vm.alertType,"elevation":"2","dismissible":""},model:{value:(_vm.alert),callback:function ($$v) {_vm.alert=$$v},expression:"alert"}},[_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("stepThree.subtitle2")))]),_c('div',[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])]),_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-form',{ref:"upload",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"7"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-file-input',{staticClass:"mb-2",attrs:{"rules":_vm.imgRules,"accept":"image/png, image/jpeg","label":_vm.$t('stepThree.identityPicture.name'),"prepend-icon":"mdi-camera","filled":"","outlined":"","dense":"","hide-details":"auto","truncate-length":"15"},model:{value:(_vm.files.photo),callback:function ($$v) {_vm.$set(_vm.files, "photo", $$v)},expression:"files.photo"}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("stepThree.identityPicture.tooltip")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-file-input',{staticClass:"mb-2",attrs:{"rules":_vm.fileRules,"accept":"image/png, image/jpeg, .pdf","label":_vm.$t('stepThree.passportPicture.name'),"truncate-length":"15","filled":"","outlined":"","dense":"","hide-details":"auto"},model:{value:(_vm.files.passport),callback:function ($$v) {_vm.$set(_vm.files, "passport", $$v)},expression:"files.passport"}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("stepThree.passportPicture.tooltip")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-file-input',{staticClass:"mb-2",attrs:{"rules":_vm.fileRules,"accept":"image/png, image/jpeg, .pdf","label":_vm.$t('stepThree.invitationPicture.name'),"truncate-length":"15","filled":"","outlined":"","dense":"","hide-details":"auto"},model:{value:(_vm.files.ticket),callback:function ($$v) {_vm.$set(_vm.files, "ticket", $$v)},expression:"files.ticket"}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("stepThree.invitationPicture.tooltip")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-file-input',{staticClass:"mb-2",attrs:{"rules":_vm.fileRules,"accept":"image/png, image/jpeg, .pdf","label":_vm.$t('stepThree.invitationLetter.name'),"truncate-length":"15","filled":"","outlined":"","required":"","dense":"","hide-details":"auto"},model:{value:(_vm.files.reservation),callback:function ($$v) {_vm.$set(_vm.files, "reservation", $$v)},expression:"files.reservation"}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("stepThree.invitationLetter.tooltip")))])])],1),_c('v-col',{staticClass:"ml-15",attrs:{"cols":"4"}},[_c('p',{staticClass:"text-justify"},[_vm._v(" "+_vm._s(_vm.$t("stepThree.fileDesc.identityPicture"))),_c('br'),_vm._v(" "+_vm._s(_vm.$t("stepThree.fileDesc.type"))),_c('br'),_vm._v(" "+_vm._s(_vm.$t("stepThree.fileDesc.size"))),_c('br'),_vm._v(" "+_vm._s(_vm.$t("stepThree.fileDesc.letter"))+" ")])])],1),_c('v-row',{staticClass:"pt-5 pr-3 pb-5"},[_c('v-btn',{staticClass:"ml-auto",attrs:{"color":"success"},on:{"click":_vm.validate}},[_vm._v(" "+_vm._s(_vm.$t("buttonsForm.valid.name"))+" ")])],1)],1)],1)],1),_vm._v(" * "),_c('small',[_vm._v(_vm._s(_vm.$t("requiredField")))])],1)],1)],1)],1)],1),_c('v-footer',{staticClass:"px-0",attrs:{"app":"","dark":""}},[_c('footer-info')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from "vue";
import VueRouter from "vue-router";
import HomePage from "@/pages/HomePage"
import SuiviDemande from "@/pages/SuiviDemande"
import Information from "@/pages/Information"
import MaintenancePage from "@/pages/MaintenancePage"
import DemandeForm from "@/pages/DemandeForm"
import FaqPage from "@/pages/FaqPage"
import ErrorPage from '@/pages/ErrorPage'
import SuccessPage from '@/pages/SuccessPage'
import PaymentPage from '@/pages/PaymentPage'
import MentionsPage from '@/pages/MentionsPage'
import UploadPage from '@/pages/UploadPage'

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'HomePage',
        component: HomePage
    },
    {
        path: '/demande-visa',
        name: 'DemandeForm',
        component: DemandeForm
    },
    {
        path: '/suivi-demande',
        name: 'SuiviDemande',
        component: SuiviDemande
    },
    {
        path: '/information',
        name: 'Information',
        component: Information
    },
    {
        path: '/maintenance',
        name: 'MaintenancePage',
        component: MaintenancePage
    },
    {
        path: '/faq',
        name: 'FaqPage',
        component: FaqPage
    },
    {
        path: '/error',
        name: 'ErrorPage',
        component: ErrorPage
    },
    {
        path: '/success',
        name: 'SuccessPage',
        component: SuccessPage
    },
    {
        path: '/checkout',
        name: 'PaymentPage',
        component: PaymentPage
    },
    {
        path: '/mentions',
        name: 'MentionsPage',
        component: MentionsPage
    },
    {
        path: '/upload/:token',
        name: 'UploadPage',
        component: UploadPage
    },

];

const router = new VueRouter({
    mode: 'history',
    routes
})


export default router;

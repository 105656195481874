<template>
  <v-layout>
    <v-main>
      <v-row class="justify-center mt-5">
        <v-card width="1175px" class="mt-3" flat color="#f1f1f1">
          <v-card-text>
            <h2 class="evisa-header mb-5">{{ $t("stepThree.subtitle2") }}</h2>
            <v-alert
              border="bottom"
              colored-border
              :type="alertType"
              elevation="2"
              dismissible
              v-model="alert"
            >
              <h2 class="title">{{ $t("stepThree.subtitle2") }}</h2>
              <div>
                {{ errorMessage }}
              </div>
            </v-alert>
            <v-container grid-list-md>
              <v-form lazy-validation ref="upload" v-model="valid">
                <v-card>
                  <v-card-text>
                    <v-row>
                      <v-col cols="7">
                        <!-- photo -->
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                              <v-file-input
                                :rules="imgRules"
                                v-model="files.photo"
                                accept="image/png, image/jpeg"
                                :label="$t('stepThree.identityPicture.name')"
                                prepend-icon="mdi-camera"
                                filled
                                outlined
                                dense
                                class="mb-2"
                                hide-details="auto"
                                truncate-length="15"
                              ></v-file-input>
                            </span>
                          </template>
                          <span>{{
                            $t("stepThree.identityPicture.tooltip")
                          }}</span>
                        </v-tooltip>

                        <!-- passeport picture -->
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                              <v-file-input
                                :rules="fileRules"
                                v-model="files.passport"
                                accept="image/png, image/jpeg, .pdf"
                                :label="$t('stepThree.passportPicture.name')"
                                truncate-length="15"
                                filled
                                outlined
                                dense
                                class="mb-2"
                                hide-details="auto"
                              ></v-file-input>
                            </span>
                          </template>
                          <span>{{
                            $t("stepThree.passportPicture.tooltip")
                          }}</span>
                        </v-tooltip>

                        <!-- ticket picture -->
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                              <v-file-input
                                :rules="fileRules"
                                v-model="files.ticket"
                                accept="image/png, image/jpeg, .pdf"
                                :label="$t('stepThree.invitationPicture.name')"
                                truncate-length="15"
                                filled
                                outlined
                                dense
                                class="mb-2"
                                hide-details="auto"
                              ></v-file-input>
                            </span>
                          </template>
                          <span>{{
                            $t("stepThree.invitationPicture.tooltip")
                          }}</span>
                        </v-tooltip>

                        <!-- reservation picture -->
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                              <v-file-input
                                :rules="fileRules"
                                v-model="files.reservation"
                                accept="image/png, image/jpeg, .pdf"
                                :label="$t('stepThree.invitationLetter.name')"
                                truncate-length="15"
                                filled
                                outlined
                                required
                                dense
                                class="mb-2"
                                hide-details="auto"
                              ></v-file-input>
                            </span>
                          </template>
                          <span>{{
                            $t("stepThree.invitationLetter.tooltip")
                          }}</span>
                        </v-tooltip>
                      </v-col>
                      <!-- Text fichier passport -->
                      <v-col cols="4" class="ml-15">
                        <p class="text-justify">
                          {{ $t("stepThree.fileDesc.identityPicture") }}<br />
                          {{ $t("stepThree.fileDesc.type") }}<br />
                          {{ $t("stepThree.fileDesc.size") }}<br />
                          {{ $t("stepThree.fileDesc.letter") }}
                        </p>
                      </v-col>
                    </v-row>
                    <v-row class="pt-5 pr-3 pb-5">
                      <v-btn color="success" @click="validate" class="ml-auto">
                        {{ $t("buttonsForm.valid.name") }}
                      </v-btn>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-form>
              * <small>{{ $t("requiredField") }}</small>
            </v-container>
          </v-card-text>
        </v-card>
      </v-row>
    </v-main>
    <v-footer app dark class="px-0">
      <footer-info></footer-info>
    </v-footer>
  </v-layout>
</template>

<script>
import FooterInfo from "@/components/FooterInfo";
import FileService from "@/services/FileService";
import ComplementaryService from '@/services/ComplementaryService'
export default {
  components: {
    FooterInfo,
  },
  data() {
    return {
      files: {
        passport: "",
        photo: "",
        ticket: "",
        reservation: "",
      },
      mFiles:[],
      alert: false,
      alertType: "",
      errorMessage: "",
      valid: false,
      fileRules: [
        (v) => !!v || this.$t("requiredField"),
        (v) => !v || v.size < 1000000 || this.$t("stepThree.fileSizeExceeded"),
        
        (v) =>
          /.+(\.jpg|\.png|\.pdf)$/.test(v.name) ||
          this.$t("stepThree.fileDesc.identityPicture"),
      ],
      imgRules: [
        (v) => !!v || this.$t("requiredField"),
        (v) => !v || v.size < 1000000 || this.$t("stepThree.fileSizeExceeded"),
        
        (v) =>
          /.+(\.jpg|\.png)$/.test(v.name) ||
          this.$t("stepThree.fileDesc.identityPicture"),
      ],
    };
  },
  methods: {
     async uploadFiles() {
      await FileService.uploadFiles(this.files).then((response) => {
        this.mFiles = response.data;
      });
    },

    async completeApp(){
      await this.uploadFiles();
      let payload = {
        token: this.$route.params.token,
        reservation : this.findFile("RESERVATION"),
        photo : this.findFile("PHOTO"),
        ticket : this.findFile("TICKET"),
        passport : this.findFile("PASSPORT")
      }
      ComplementaryService.complete(payload).then(()=>{
          this.alert = true;
          this.alertType = "success"
          this.errorMessage = this.$t("upload.success")
      }).catch(()=>{
           this.alert = true;
           this.alertType = "error"
          this.errorMessage = this.$t("upload.error")
      })
    },


    findFile(type) {
      console.log(this.mFiles);
      let find = this.mFiles.find((e) => e.type == type);
      console.log(type, find);
      return find.id;
    },

    validate() {
      if (this.$refs.upload.validate()) {
        this.valid = true;
        this.completeApp()
      }else{
        this.valid=false
      }
    },
  },
};
</script>

import axios from "axios";
const PAY_API_URL = process.env.VUE_APP_MSPAY_URL
const CORE_API_URL = process.env.VUE_APP_MSCORE_URL


const state = () => ({
    paymentUrl: null,
    payFailed: false,
    productList: [],
    application: {
        customerEmail: "",
        price: "",
        referenceId: ""
    }
});

const getters = {
    getApplication(state) {
        if (state.application.referenceId != "") {
            return state.application
        } else {
            let jsonApp = localStorage.getItem("app")
            if (jsonApp != null) {
                state.application = JSON.parse(jsonApp)
            }
        }
        return state.application
    },

    getPaymentUrl(state) {
        return state.paymentUrl
    },

    isPayFailed(state) {
        return state.payFailed
    },
    getProducts(state) {
        return state.productList;
    }
}

const mutations = {
    saveApplication(state, data) {
        if (data == null) {
            state.application.price = ""
            state.application.referenceId = ""
            state.application.customerEmail = ""
            localStorage.removeItem("app")
        } else {
            state.application.price = data.price
            state.application.referenceId = data.trackingNumber
            state.application.customerEmail = data.email
            localStorage.setItem("app", JSON.stringify(state.application))
        }

    },

    savePaymentUrl(state, data) {
        state.paymentUrl = data
    },

    savePayFailed(state, data) {
        state.payFailed = data
    },

    saveProducts(state, data) {
        state.productList = data
    }
}

const actions = {
    async checkout({ commit }, payload) {
        const response = await axios.post(`${PAY_API_URL}/checkout`, payload).catch(() => {
            commit("savePaymentUrl", null)
            commit("savePayFailed", true)
        })
        if (response && response.data) {
            commit("savePaymentUrl", response.data.url)
        } else {
            commit("savePaymentUrl", null)
            commit("savePayFailed", true)
        }
    },

    async loadProducts({ commit }) {
        const response = await axios.get(`${CORE_API_URL}/products`).catch(() => {
            
        })
        if (response && response.data) {
            commit("saveProducts", response.data)
        }
    },

    async saveApplication({ commit }, payload) {
        const response = await axios.post(`${CORE_API_URL}/application`, payload).catch(() => {
            commit("saveApplication", null)
        })
        if (response && response.data) {
            commit("saveApplication", response.data)
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
import axios from 'axios'

const API_URL = process.env.VUE_APP_MSCORE_URL

class ComplementaryService{
    complete(payload){
        return axios.post(`${API_URL}/complete/files`, payload)
    }
}

export default new ComplementaryService()